/* Terminal */

.terminal {
  /* Spacing */
  --terminal-spacing-md: 5px;
  --terminal-spacing-lg: calc(var(--terminal-spacing-md) * 2);
  --terminal-spacing-xl: calc(var(--terminal-spacing-md) * 3);
  --terminal-spacing-xxl: calc(var(--terminal-spacing-md) * 4);
  /* Shape */
  --terminal-border-radius: 10px;
  --terminal-border-width: 1px;
  /* Text */
  --terminal-text-prompt: "➜";
  --terminal-text-cursor: "_";
  /* --terminal-font-size: 1.4rem; */
}

.terminal--dark {
  --terminal-colour-darker: #1c1e2c;
  --terminal-colour-dark: rgb(from #1c1e2c r g b / 1);
  --terminal-colour-text: #949dcb;
  --terminal-colour-text-contrast: white;
  --terminal-colour-border-inside: #ffffff1c;
  --terminal-colour-border-outside: rgb(
    from var(--terminal-colour-border-inside) r g b / 0.01
  );
}

.terminal--bright {
  --terminal-colour-darker: white;
  --terminal-colour-dark: white;
  --terminal-colour-text: #101828;
  --terminal-colour-text-contrast: black;
  --terminal-colour-border-inside: #e5e7eb;
  --terminal-colour-border-outside: #ffffff1c;
}

.terminal .red {
  color: #ef766d;
}

.terminal .white {
  color: var(--terminal-colour-text-contrast);
}

.terminal .yellow {
  color: #fecb6b;
}

.terminal .blue {
  color: #72bdff;
}

.terminal .nearwhite {
  color: aliceblue;
}

.terminal .green {
  color: mediumspringgreen;
}

.terminal {
  background: var(--terminal-colour-dark);
  backdrop-filter: blur(4px);
  color: var(--terminal-colour-text);
  width: 100%;
  border-radius: var(--terminal-border-radius);
  border: var(--terminal-border-width) solid
    var(--terminal-colour-border-outside);
  overflow: hidden;
  font-family: monaco, monospace;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  /* height: 230px; */
  /* max-width: 600px; */
  /* max-height: 270px; */
  /* font-size: var(--terminal-font-size); */
}

.terminal-header {
  background: var(--terminal-colour-darker);
  padding: var(--terminal-spacing-lg);
  position: relative;
  display: flex;
  height: 38px;
  background: var(--terminal-colour-darker)
    url(data:image/svg+xml;base64,ICAgICAgICA8c3ZnCiAgICAgICAgICB3aWR0aD0iNTIiCiAgICAgICAgICBoZWlnaHQ9IjEyIgogICAgICAgICAgdmlld0JveD0iMCAwIDUyIDEyIgogICAgICAgICAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogICAgICAgID4KICAgICAgICAgIDxjaXJjbGUgY3g9IjQ2IiBjeT0iNiIgcj0iNiIgZmlsbD0iIzI4Qzg0MCIgLz4KICAgICAgICAgIDxjaXJjbGUgY3g9IjI2IiBjeT0iNiIgcj0iNiIgZmlsbD0iI0ZFQkMyRSIgLz4KICAgICAgICAgIDxjaXJjbGUgY3g9IjYiIGN5PSI2IiByPSI2IiBmaWxsPSIjRkY1RjU3IiAvPgogICAgICAgIDwvc3ZnPg==)
    no-repeat 15px center;
  padding-left: 80px;
  color: #ffffff57;
  border-bottom: var(--terminal-border-width) solid
    var(--terminal-colour-border-inside);
}

.terminal-copyThis {
  margin-left: auto;
  padding: 2px;
  cursor: pointer;
}

.terminal-copyThis svg {
  opacity: 0.8;
  stroke: var(--terminal-colour-text);
  width: 17px;
  position: relative;
  top: -4px;
  right: -2px;
}

.terminal-copyThis:hover svg {
  opacity: 1;
}

.terminal-content {
  padding: var(--terminal-spacing-xl);
  overflow-y: auto;
  height: calc(100% - 50px);
  scrollbar-color: #545875 #545875;
  scrollbar-width: thin;
}

.terminal-content::after {
  content: "";
  height: 1rem;
  display: block;
}

.terminal-line--active:not(.terminal-line--noPrompt)::before,
.terminal-line--done:not(.terminal-line--noPrompt)::before {
  content: var(--terminal-text-prompt);
  margin-right: 7px;
}

.terminal-line--noPrompt {
  padding-left: 14px;
}

.terminal-line--active::after {
  content: var(--terminal-text-cursor);
  color: var(--terminal-colour-text);
  position: relative;
  animation: cursorBlink 1.2s infinite;
}

@keyframes cursorBlink {
  0% {
    opacity: 1;
  }

  49% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.terminal-line.fadeOut {
  animation: fadeOutShrink 0.5s ease-out forwards;
}

@keyframes fadeOutShrink {
  0% {
    opacity: 1;
    max-height: 100px;
  }

  100% {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
  }
}