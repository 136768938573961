/* Color */
/* Shape */
/* Layout */
/* Text */

.font--md {
  font-size: 1.6rem;
}

.font--sm {
  font-size: 1.4rem;
}

.font--ssm {
  font-size: 1.2rem;
}

/* BUTTONS -------------------------------------------------- */

.button {
  display: inline-flex;
  border-radius: var(--border-radius);
  color: var(--colors-white);
  text-decoration: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid;
  border-color: #ffffff17;
  box-shadow: inset 0px -1px 4px -2px #000000, 1px 1px 0px #00000038;
}

.button:hover {
  border-color: #ffffff27;
  opacity: 0.95;
}

.button--primary {
  background: var(--colors-primary);
}

.button--secondary {
  background-color: var(--colors-secondary);
}

.button--outline {
  border: 1px solid var(--colors-grey900);
  color: var(--colors-grey400);
}

.button--outline:hover {
  border: 1px solid var(--colors-grey800);
  color: var(--colors-grey300);
}

.button--dark {
  background-color: var(--colors-grey900);
  color: var(--colors-grey400);
}

.button--dark:hover {
  background-color: hsl(from var(--colors-grey900) h s calc(l + 3) / 1);
  color: var(--colors-grey200);
}

.button--dark-alt {
  background-color: var(--colors-grey800);
  color: var(--colors-grey300);
}

.button--dark-alt:hover {
  background-color: var(--colors-grey800);
  color: var(--colors-grey200);
}

.button--size-sm {
  padding: 4px 12px;
  font-size: 1.4rem;
}

.button--size-md {
  padding: 6px 16px;
}

.button--size-lg {
  padding: 10px 36px;
  font-size: 2rem;
}

.button--isCancel {
  background: var(--colors-grey900);
}

/* Pagination Buttons */

.button--pagination {
  padding: 4px 12px;
  font-size: 1.4rem;
  border-radius: var(--border-radius);
  background: var(--colors-admin-section);
  margin-right: 6px;
  user-select: none;
}

.button--paginationActive {
  background: var(--colors-admin-selected);
  color: var(--colors-black);
}

/* Centered Loading Animation */

.button--centerLoader {
  position: relative;
  overflow: hidden;
}

.button--centerLoader .spinner {
  position: absolute;
  margin: 0px;
}

.button--centerLoader::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  background: #00000087;
  top: 0;
  bottom: 0;
  animation: fadeIn 0.2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* GRID UTILS ----------------------------------------------- */

.grid {
  display: grid;
}

.grid--2col,
.grid--3col {
  grid-template-columns: repeat(12, minmax(auto, 1fr));
  grid-gap: 12px;
}

.grid--2col > *,
.grid--3col > * {
  grid-column-start: span 12;
}

.grid--gap-sm {
  grid-gap: 6px;
}

.grid--gap-md {
  grid-gap: 12px;
}

.grid--gap-xl {
  grid-gap: 16px;
}

@media (min-width: 768px) {
  .grid--2col > *:nth-of-type(1) {
    grid-column: 1 / 7;
  }
  .grid--2col > *:nth-of-type(2) {
    grid-column: 7 / 13;
  }
}

@media (min-width: 768px) {
  .grid--3col > *:nth-of-type(1) {
    grid-column: 1 / 5;
  }
  .grid--3col > *:nth-of-type(2) {
    grid-column: 5 / 9;
  }
  .grid--3col > *:nth-of-type(3) {
    grid-column: 9 / 13;
  }
}

/* AVATAR ------------------------------------------------ */

.avatar {
  color: var(--colors-white);
  background-color: var(--colors-primary);
  border-radius: 100px;
  width: 40px;
  height: 40px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 1.4rem;
  letter-spacing: -1px;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
}

/* STATUS MESSAGE ---------------------------------------------------- */

.statusMessage--error {
  background: hsl(from var(--colors-red) h s 2 / 0.4);
  color: var(--colors-red);
}

.statusMessage--success {
  background: #0000006e;
  color: var(--colors-green);
}

/* POPOVER ----------------------------------------------- */

.popOver {
  position: absolute;
  background: var(--colors-white);
  border-radius: var(--border-radius);
  color: var(--colors-black);
  top: 50px;
  right: 0;
  border: 1px solid var(--colors-grey100);
  z-index: 20;
  box-shadow: 0px 1px 10px #00000054;
  min-width: 180px;
  font-size: 1.4rem;
  font-weight: 400;
}

.popOver:before {
  content: "";
  position: absolute;
  top: -5px;
  right: 14px;
  background: var(--colors-white);
  width: 8px;
  height: 8px;
  transform: rotate(45deg);
  border-top: 1px solid var(--colors-grey100);
  border-left: 1px solid var(--colors-grey100);
  z-index: -1;
}

.popOver-list {
  list-style-type: none;
  margin: 0;
  padding: 5px 4px;
}

.popOver-listItem {
  display: grid;
  border-bottom: 1px solid #dddddd61;
}

.popOver-link {
  text-decoration: none;
  color: var(--colors-black);
  padding: 8px 12px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 27px 1fr;
  align-items: center;
}

.popOver-link:hover {
  background: var(--colors-grey100);
}

.popOver-icon {
  stroke: var(--colors-black);
  opacity: 0.5;
}

/* POPOVER --------------------------------------------------- */

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 2000;
}

.popup-backdrop {
  background: #000000ab;
  width: 100%;
  height: 100%;
  position: absolute;
}

.popup-content {
  background: var(--colors-white);
  position: relative;
  padding: 12px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  width: 100%;
}

.popup-content::after {
  content: "";
  position: absolute;
  background: #ffffff;
  bottom: -100px;
  left: 0;
  width: 100%;
  height: 100px;
}

.popup-content h1 {
  font-size: 1.8rem;
  margin: 0 0px 10px 0px;
}

.popup-buttonGroup {
  display: grid;
  gap: 6px;
  grid-auto-flow: column;
}

@media (min-width: 768px) {
  .popup {
    align-items: center;
  }
  .popup-content {
    padding: 12px;
    border-radius: 6px;
    width: auto;
    max-width: 300px;
  }
  .popup-content::after {
    height: 0;
    bottom: 0;
    content: none;
  }
}

/* DATE --------------------------------------------------- */

.date {
  display: flex;
  align-items: center;
  color: var(--colors-grey500);
  font-size: 1.3rem;
}

.date svg {
  stroke: var(--colors-grey500);
}

/* BUBBLE --------------------------------------------------- */

.bubble {
  font-size: 1.2rem;
  color: var(--colors-grey600);
  border: 1px solid var(--colors-grey800);
  padding: 4px 8px;
  border-radius: var(--border-radius);
}

/* LIST --------------------------------------------------- */

.list {
  display: grid;
  grid-gap: 4px;
  font-size: 1.4rem;
  margin-bottom: 4px;
  overflow: hidden;
}

.list-item {
  display: grid;
  grid-gap: 12px;
  align-items: center;
  background: var(--colors-admin-section);
  padding: 6px;
  border-radius: 9px;
  border: 1px solid #dddddd12;
  text-decoration: none;
  color: inherit;
  transition: background-color var(--hover-transition-time);
}

.list-item:hover {
  background-color: var(--colors-admin-section--hover);
}

.list-icon {
  height: 100%;
  background: var(--colors-secondary);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--colors-white);
  font-size: 10px;
  min-height: 25px;
}

.list-title {
  text-decoration: none;
  color: var(--colors-white);
}

.list-footer {
  margin-top: 12px;
  padding: 0 4px;
  display: flex;
  justify-content: flex-end;
}

/* List overrides */

.list--games-recent {
  grid-template-columns: 30px auto;
}

.list--jobs-recent {
  grid-template-columns: 30px auto max-content;
}

.recentJobs-nameWrap {
  display: flex;
  flex-direction: column;
}

.recentJobs-nameWrap svg {
  fill: var(--colors-white);
  position: relative;
  top: 2px;
}

.recentJobs-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* BREADCRUMBS  --------------------------------------- */

.breadCrumb {
}

.breadCrumb > *:after {
  content: ">";
  margin-left: 5px;
}
.breadCrumb > *:last-child::after {
  content: "";
  margin-left: 5px;
}

.breadCrumb a {
  color: var(--colors-white);
  margin-right: 5px;
}

/* TABS ----------------------------------------------- */

.tabs {
  margin-bottom: 12px;
  display: flex;
  font-size: 1.4rem;
}

.tab {
  padding: 6px 14px;
  background-color: var(--colors-admin-section);
  color: var(--colors-white);
  border-radius: var(--border-radius);
  margin-right: 6px;
  text-decoration: none;
  text-transform: capitalize;
  transition: background-color var(--hover-transition-time);
}

.tab:hover {
  background-color: var(--colors-admin-section--hover);
}

.tab--active {
  background: var(--colors-admin-selected);
  color: var(--colors-admin-section);
}

.tab--active:hover {
  background: var(--colors-admin-selected);
}

/* STATUS BUBBLE ----------------------------------------------- */

.status {
  background: #f6f6f6;
  color: #bdbdbd;
  border: 1px solid #e7e7e7;
  display: inline-flex;
  border-radius: 6px;
  padding: 2px 6px;
  font-size: 1.2rem;
  text-transform: capitalize;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0px -1px 0px #00000040;
}

.status--green {
  color: hsl(from var(--colors-green) h s l / 0.8);
  background: hsl(from var(--colors-green) h s l / 0.1);
  border-color: hsl(from var(--colors-green) h s l / 0.05);
}

.status--red {
  color: hsl(from var(--colors-red) h s l / 0.8);
  background: hsl(from var(--colors-red) h s l / 0.1);
  border-color: hsl(from var(--colors-red) h s l / 0.05);
}

.status--orange {
  background: #fff8ed;
  color: #fa7315;
  border-color: #ffe1bf47;
}

.status--blue {
  color: hsl(from var(--colors-admin-selected) h s l / 0.8);
  background: hsl(from var(--colors-admin-selected) h s l / 0.1);
  border-color: hsl(from var(--colors-admin-selected) h s l / 0.05);
}

/* TIMELINE ------- */

.timeline {
  display: inline-flex;
  align-items: center;
}

.timeline-step {
  min-width: 4px;
  height: 6px;
  background: hsl(from var(--colors-green) h s l / 0.15);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 3px;
}

.timeline-step--active {
  height: 18px;
  padding-left: 12px;
  padding-right: 12px;
  background: hsl(from var(--colors-green) h s l / 0.2);
}

.timeline-step--todo {
  height: 18px;
  background: hsl(from var(--colors-green) h s l / 0.2);
}

.timeline-step--success {
  height: 18px;
  background: hsl(from var(--colors-green) h s l / 0.3);
}

/* Toggle */

.toggleWrap {
  padding: 4px;
  border: 1px solid var(--colors-grey800);
  border-radius: 10px;
  background: var(--colors-grey950);
  font-size: 1.2rem;
}

.toggle {
  display: flex;
  position: relative;
}

.toggle-slider {
  background: var(--colors-admin-selected);
  position: absolute;
  height: 100%;
  border-radius: 6px;
  top: 0;
  left: 0;
}

.toggle-option {
  z-index: 10;
  cursor: pointer;
  padding: 2px 8px;
  color: hsl(from var(--colors-admin-selected) h s l / 0.2);
  user-select: none;
  transition: color 0.25s;
}

.toggle-option--selected {
  color: var(--colors-black);
  cursor: default;
}

/* StatusMessage */

.statusMessage {
  overflow: hidden;
}

.statusMessage-text {
  font-size: 1.4rem;
  text-align: left;
  font-weight: 400;
  padding: 10px 14px;
  border-radius: 7px;
}

/* EmptyState */

.emptyState {
  font-size: 1.4rem;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--colors-admin-section--darker);
  border-radius: var(--border-radius-lg);
  border: 1px solid #ffffff0f;
}

.emptyState-icon svg {
  stroke: #274b5c;
  stroke-width: 0.75;
  width: 60px;
  height: 60px;
}

.emptyState-message {
  color: #274b5c;
}

.emptyState-action {
  text-align: center;
  line-height: 1.4;
  margin-top: 4px;
}

.emptyState-action a {
  color: var(--color-link);
}

/* Banner */

.banner {
  background: rgb(255 255 255 / 5%);
  font-size: 1.4rem;
  padding: 16px 22px;
  color: #ffffff;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  gap: 12px;
  position: relative;
  overflow: hidden;
  border-radius: 0px;
  width: 100vw;
  left: -25px;
}

.banner:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0px;
  background: #49639b36;
  right: 0;
  z-index: -1;
}

.banner:after {
  content: "";
  position: absolute;
  left: 0px;
  bottom: -2px;
  z-index: -1;
  right: 0px;
  border-image: linear-gradient(90deg, #feb057 0%, #da89b6 100%) 1;
  border-bottom-width: 3px;
  border-bottom-style: solid;
  opacity: 0.2;
}

.banner-text {
  border-left: 1px solid white;
  padding-left: 12px;
}

.banner svg {
  stroke: white;
}

@media (min-width: 768px) {
  .banner {
    border-radius: var(--border-radius-sm);
    width: auto;
    left: auto;
  }
  .banner--top {
    margin-top: 30px;
    margin-bottom: 10px;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000c7;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: var(--colors-grey900);
  padding: 24px 18px;
  color: var(--colors-white);
  border-radius: var(--border-radius-md);
  border: 1px solid #ffffff1c;
  max-width: 80%;
  position: relative;
  box-shadow: 0 4px 10px #ffffff2e;
}

.modal-close {
  position: absolute;
  background: #ffffff30;
  top: 10px;
  right: 10px;
  cursor: pointer;
  padding: 1px;
  border-radius: 8px;
  display: inline-flex;
}

.modal-close svg {
  stroke: white;
}

.modal--quickstart {
  display: flex;
  flex-direction: column;
}

.modal--quickstart-headline {
  font-size: 1.4rem;
  margin: 0;
  font-weight: 400;
}

.modal--quickstart-intro {
  margin: 8px 0;
  font-size: 1.4rem;
  color: var(--colors-grey400);
}

.modal--quickstart-form {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .modal-content {
    max-width: 400px;
  }
}
